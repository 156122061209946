
import { defineComponent } from 'vue';

import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import moment from 'moment';

export default defineComponent({
  name: 'add-claim-3',
  components: {
    Datatable,
  },
  data() {
    return {
      bill: {
        tranche_id: '',
        entity_id: '',
        entity_contract_id: '',
        bill_date: '',
        bill_sequence_no: '',
      },
      tranches: [],
      entityInfos: [],
      contractInfos: [],
      allBillNo: [],
      billList: [],
      batchList: [],
      claimBillkey: 0,
      totalClaim1Trainee: 0,
      totalClaim2Trainee: 0,
      totalClaim3Trainee: 0,
      totalEmployeedTrainee: 0,
      totalCertifiedTrainee:0,
      totalEmpoymentPer: 0,
      load: false,
      loading: false,
      courseInfoData: false,
      showBillList: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getTranche();
    await this.getAssociation();
    this.bill.bill_date = moment().format('YYYY-MM-DD');
    this.emitter.on('claim-3-bill-update', async () => {
      await this.getBatchList();
    });
  },
  methods: {
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      this.load = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityContract() {
      this.load = true;
      await ApiService.get(
        'entity/contract/list?entity_info_id=' +
          this.bill.entity_id +
          '&tranche_id=' +
          this.bill.tranche_id
      )
        .then((response) => {
          this.contractInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAllBillNo() {
      this.load = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        'bill/claim_3/eligible_bill_no?entity_id=' +
          this.bill.entity_id +
          '&tranche_id=' +
          this.bill.tranche_id +
          '&entity_contract_id=' +
          this.bill.entity_contract_id + 
          '&institute_info_id=' +
          institute_info_id +
          '&active_status=3'
      )
        .then((response) => {
          this.allBillNo = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getBatchList() {
      if(this.bill.bill_sequence_no){
        this.load = true;
          let institute_info_id = '';
          if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
            institute_info_id = VueCookieNext.getCookie('_institute_info_id');
          }
          await ApiService.get(
            'bill/claim_3/eligible_batch?entity_id=' +
              this.bill.entity_id +
              '&tranche_id=' +
              this.bill.tranche_id +
              '&entity_contract_id=' +
              this.bill.entity_contract_id +
              '&bill_sequence_no=' +
              this.bill.bill_sequence_no + 
              '&institute_info_id=' +
              institute_info_id 
          ).then((response) => {
            if (response.status == 200) {
              if (response.data.status == 'error') {
                this.load = false;
                Swal.fire({
                  title: 'Error!',
                  html: response.data.message,
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: 'Close',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                });
              } else {
                this.billList = response.data.data;
                this.totalClaim1Trainee = response.data.data[0].total_claim1_trainee;
                this.totalClaim2Trainee = response.data.data[0].total_claim2_trainee;
                this.totalClaim3Trainee = response.data.data[0].total_claim3_trainee;
                this.totalEmployeedTrainee = response.data.data[0].total_employeed_trainee;
                this.totalCertifiedTrainee = response.data.data[0].total_certified_trainee;
                this.totalEmpoymentPer = (this.totalEmployeedTrainee / this.totalClaim3Trainee) * 100;
                this.claimBillkey += 1;
                this.showBillList = true;
                this.load = false;
              }
            } else {
              let err = '';
              for (const field of Object.keys(response.data.errors)) {
                err += response.data.errors[field][0] + '<br>';
              }
              Swal.fire({
                title: 'Please check all the required field',
                html: err,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Close',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              });
              this.load = false;
            }
          });
      }else{
        Swal.fire({
          title: 'Warning!',
          html: 'Please select bill no first.',
          icon: 'warining',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
        });
      }
    },
    async submitBill() {
      let user_id = VueCookieNext.getCookie('_seip_user');
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      user_id =  user_id.id;
      let data = {
        tranche_id: this.bill.tranche_id,
        entity_id: this.bill.entity_id,
        entity_contract_id: this.bill.entity_contract_id,
        bill_sequence_no: this.bill.bill_sequence_no,
        institute_info_id: institute_info_id,
        bill_date: this.bill.bill_date,
        total_empoyment_per: this.totalEmpoymentPer,
        total_claim2_trainee: this.totalClaim2Trainee,
        total_claim3_trainee: this.totalClaim3Trainee,
        total_employeed_trainee: this.totalEmployeedTrainee,
        total_certified_trainee: this.totalCertifiedTrainee,
        user_id: user_id,
      };

      this.loading = true;
      await ApiService.post('bill/claim_3/save', data)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            if (response.data.message) {
              Swal.fire({
                title: 'Error!',
                html: response.data.message,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Close',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              });
            } else {
              this.billList = [];
              this.batchList = [];
              this.bill = {
                  tranche_id: '',
                  entity_id: '',
                  entity_contract_id: '',
                  bill_sequence_no: '',
                  bill_date: this.bill.bill_date,
              };
              this.totalClaim1Trainee = 0;
              this.totalClaim2Trainee = 0;
              this.totalClaim3Trainee = 0;
              this.totalEmployeedTrainee = 0;
              this.totalCertifiedTrainee = 0;
              this.totalEmpoymentPer = 0;
              this.claimBillkey += 1;
              this.showBillList = false;
              Swal.fire({
                title: 'Success!',
                text: response.data.data,
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {});
            }
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
    confirmSubmitBill() {
      Swal.fire({
        title: "You can't edit bill after submit. Are you sure to submit ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Submitted!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.submitBill();
        }
      });
    },
    moment() {
      return moment();
    },
  },
  setup() {},
});
